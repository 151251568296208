import HttpService from '@/services/HttpService';
import { error } from '@/utils/error';
const getHospitals = async ({ postal, insurance, brand }) => {
    const path = '/clientcontroller/salesfunnelclient/ophalenziekenhuizen';
    const payload = {
        Locatie: postal,
        Pakket: insurance,
        Propositie: brand ?? 'BAP'
    };
    const { data } = (await HttpService.post(path, payload));
    if (data.isSucces === false) {
        error('Client controller call was unsuccessful', {
            path,
            payload: JSON.stringify(payload)
        });
    }
    return data;
};
export default {
    getHospitals
};
