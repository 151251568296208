import HttpService from '@/services/HttpService';
import { error } from '@/utils/error';
const getContent = async (brand) => {
    const path = '/clientcontroller/salesfunnelclient/ophalentvkeuzehulpcontent';
    const payload = {
        Propositie: brand
    };
    const { data } = (await HttpService.post(path, payload));
    if (data.isSucces === false) {
        error('Client controller call was unsuccessful', {
            path,
            payload: JSON.stringify(payload)
        });
    }
    return data.tvKeuzehulpResponse;
};
export default {
    getContent
};
