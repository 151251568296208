var SeverityLevel;
(function (SeverityLevel) {
    SeverityLevel[SeverityLevel["Verbose"] = 0] = "Verbose";
    SeverityLevel[SeverityLevel["Information"] = 1] = "Information";
    SeverityLevel[SeverityLevel["Warning"] = 2] = "Warning";
    SeverityLevel[SeverityLevel["Error"] = 3] = "Error";
    SeverityLevel[SeverityLevel["Critical"] = 4] = "Critical";
})(SeverityLevel || (SeverityLevel = {}));
const createDefaultProps = () => ({
    app: 'salesfunnel',
    host: location.hostname,
    location: location.pathname
});
const logMessage = (msg, lvl, props) => {
    if (location.hostname !== 'zka.zdn.local' && process.env.NODE_ENV === 'production')
        return;
    switch (lvl) {
        case SeverityLevel.Verbose:
            props ? console.log(msg, props) : console.log(msg);
            break;
        case SeverityLevel.Information:
            props ? console.info(msg, props) : console.info(msg);
            break;
        case SeverityLevel.Warning:
            props ? console.warn(msg, props) : console.warn(msg);
            break;
        case SeverityLevel.Error:
            props ? console.error(msg, props) : console.error(msg);
            break;
        default:
            return;
    }
};
const trackTrace = (msg, lvl, props) => {
    logMessage(msg, lvl, props);
    if (!window.appInsights)
        return;
    window.appInsights.trackTrace({ message: msg, severityLevel: lvl, properties: { ...createDefaultProps(), ...props } });
};
const trackException = (err, props) => {
    logMessage(err, SeverityLevel.Error, props);
    if (!window.appInsights)
        return;
    window.appInsights.trackException({ exception: err, properties: { ...createDefaultProps(), ...props } });
};
const verbose = (msg, props) => trackTrace(msg, SeverityLevel.Verbose, props);
const info = (msg, props) => trackTrace(msg, SeverityLevel.Information, props);
const warn = (msg, props) => trackTrace(msg, SeverityLevel.Warning, props);
const error = (msg, props) => {
    if (msg instanceof Error) {
        trackException(msg, props);
    }
    else {
        trackTrace(msg, SeverityLevel.Error, props);
    }
};
export { verbose, info, warn, error };
