import HttpService from '@/services/HttpService';
import { error } from '@/utils/error';
const getContent = async (brand) => {
    const path = `/clientcontroller/salesfunnelclient/ophalenrenderinfo?propositie=${brand}`;
    const { data } = (await HttpService.get(path));
    if (data.taal === undefined) {
        error('Client controller call was unsuccessful', {
            path,
            brand
        });
    }
    return data;
};
export default {
    getContent
};
